
import BeforeAfterImagesContainer from '../common/BeforeAfterImagesContainer.vue'
import PointOfInterestInfo from '../common/PointOfInterestInfo.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MainPageInfoColumn',
  components: {
    BeforeAfterImagesContainer,
    PointOfInterestInfo
  },
  computed: {
    poiListNotEmpty() {
      return this.$store.state.pois.length > 0
    },
    currentPoi() {
      return this.$store.state.pois[this.$store.state.selectedPoiIdx]
    },
    currentEntry() {
      return this.$store.state.pois[this.$store.state.selectedPoiIdx].linkedEntries[this.$store.state.selectedEntryIdx]
    }
  },
  methods: {
    async disablePoi() {
      const poiResponse = await this.apiHttp.put(`/api/v1.0/admin/pois/${this.currentPoi.id}/status`, {
        status: 'disabled'
      });

      if (poiResponse.status < 300) {
        this.currentPoi.status = 'disabled'
      }
    },
  }
})
