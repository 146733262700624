function getAmplifyConfig () {
  return {
    Auth: {
      identityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID!,
      region: process.env.VUE_APP_AWS_REGION!
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,

      AWSPinpoint: {
        appId: process.env.VUE_APP_PINPOINT_APP_ID!,
        region: process.env.VUE_APP_AWS_REGION!,
        bufferSize: 100,
        flushSize: 100,
        flushInterval: 5000, // 5s
        resendLimit: 5
      },
      AWSKinesis: {
        region: process.env.VUE_APP_AWS_REGION!,
        bufferSize: 100,
        flushSize: 100,
        flushInterval: 5000, // 5s
        resendLimit: 5
      }
    },
  }
}

export {
  getAmplifyConfig
}
