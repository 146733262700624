import { DivIcon, Icon, Point } from 'leaflet';

export function makeIconCreateFunction(cssClassName: string) {
  return (cluster) => new DivIcon({
    html: `<div><span>${cluster.getChildCount()}</span></div>`,
    className: cssClassName,
    iconSize: new Point(40, 40)
  });
}

function makeMarker(pngName: string) {
  return new Icon({
    iconUrl: `/img/markers/${pngName}.png`,
    iconAnchor: [16, 37],
  });
}

export function makeRegularMarker() {
  return makeMarker('photo')
}

export function makeSelectedRegularMarker() {
  return makeMarker('photo_selected')
}

export function makeDisabledMarker() {
  return makeMarker('photo_disabled')
}

export function makeSelectedDisabledMarker() {
  return makeMarker('photo_selected_disabled')
}

export function makeGeocodedMarker() {
  return makeMarker('photo_geocoded_blue')
}

export function makeNewPoiMarker() {
  return makeMarker('new_poi')
}

export function getOpenStreetMapAttribution() {
  return 'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
}
