
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'AdminGeneralInformationTab',
  data() {
    return {
      apiVersion: '',
      frontendConfigValues: Object.keys(process.env).filter(k => k.startsWith('VUE_APP')).map(k => ([k.replace('VUE_APP_', ''), process.env[k]])),
      backendConfigValues: []
    }
  },
  async mounted() {
    const version = await this.apiHttp.get('/api/v1.0/admin/version');
    this.$data.backendConfigValues = version.data.split('\n').map(config => [config.split(': ')[0],config.split(': ')[1]])
  },
  methods: {
    populate() {
      console.log('populate')
    }
  }
})
