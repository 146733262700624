
import { defineComponent, PropType } from 'vue'
import { Entry, Poi } from '@/utils/types';

export default defineComponent({
  name: 'PointOfInterestInfo',
  props: {
    poi: {
      type: Object as PropType<Poi>,
      required: true,
    },
    entry: {
      type: Object as PropType<Entry>,
      required: true
    }
  },
  data() {
    return {
      favorites: [] as string[],
      poiLikes: {},
      isHoveringStarEmpty: false,
      isHoveringStar: false
    }
  },
  watch: {
    'poi': async function() {
      const likes = await this.apiMetaHttp.get(`/pois/${this.poi.id}`)
      if (likes.status < 300 && this.poiLikes[this.poi.id] === undefined) {
        this.poiLikes[this.poi.id] = likes.data.count
      }
    }
  },
  async mounted() {
    if (this.$store.state.user !== undefined) {
      const favorites = await this.apiMetaHttp.get(`/users/${this.$store.state.user.id}/favorites`)
      if (favorites.status < 300) {
        this.favorites = favorites.data
      }

      const likes = await this.apiMetaHttp.get(`/pois/${this.poi.id}`)
      if (likes.status < 300 && this.poiLikes[this.poi.id] === undefined) {
        this.poiLikes[this.poi.id] = likes.data.count
      }
    }
  },
  methods: {
    nextEntry() {
      this.$store.commit('nextImage')
    },
    previousEntry() {
      this.$store.commit('previousImage')
    },
    parseHTML(input) {
      const doc = new DOMParser().parseFromString(input, 'text/html');
      return doc.documentElement.textContent;
    },
    async likePoi() {
      if (this.$store.state.user !== undefined) {
        const favorites = await this.apiMetaHttp.put(`/users/${this.$store.state.user.id}/favorites/${this.poi.id}`)
        if (favorites.status < 300) {
          this.favorites.push(this.poi.id)
          if (this.poiLikes[this.poi.id] !== undefined) {
            this.poiLikes[this.poi.id] += 1
          }
        }
      }
    },
    async unlikePoi() {
      if (this.$store.state.user !== undefined) {
        const favorites = await this.apiMetaHttp.delete(`/users/${this.$store.state.user.id}/favorites/${this.poi.id}`)
        if (favorites.status < 300) {
          this.favorites.splice(this.favorites.indexOf(this.poi.id), 1)
          if (this.poiLikes[this.poi.id] !== undefined) {
            this.poiLikes[this.poi.id] -= 1
          }
        }
      }
    }
  }
})
