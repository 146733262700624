import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import MainPage from './views/MainPage.vue'
import OAuthHandler from './views/OAuthHandlerPage.vue'
import AppRouter from './Router.vue'
import SharePlace from './views/SharePlacePage.vue'
import LoginPage from './views/LoginPage.vue'
import AdminPage from './views/AdminPage.vue'
import store from './store/store'
import * as VueRouter from 'vue-router'
import HTTPMixin from './mixins/http'

const routes = [
    { path: '/', name: 'landing', component: MainPage },
    { path: '/p/:id', name: 'poi', component: MainPage },
    { path: '/login', name: 'login', component: LoginPage },
    { path: '/share', component: SharePlace },
    { path: '/admin', component: AdminPage },
    { path: '/oauth/:provider', component: OAuthHandler },
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes
})

const app = createApp(AppRouter)
app.mixin(HTTPMixin)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.mount('#app')
