
import SharePlaceImageCropper from './SharePlaceImageCropper.vue'
import { defineComponent } from 'vue';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default defineComponent({
  name: 'SharePlaceCropRow',
  components: { SharePlaceImageCropper, VueSlider },
  props: {
    index: {
      type: Number,
      required: true
    },
    beforeTempId: {
      type: String,
      required: true
    },
    afterTempId: {
      type: String,
      required: true
    }
  },
  emits: ['done', 'cancel'],
  data() {
    return {
      beforeCropInfo: undefined,
      afterCropInfo: undefined,
      beforeYear: null,
      afterYear: null,
      opacity: 0.5
    }
  },
  methods: {
    primaryCropperChanged(event) {
      this.$refs.secondarycropper.setAspectRatio(event.width / event.height)
    },
    async next() {
      const body = {
        after: {
          uuid: this.afterTempId,
          year: this.afterYear,
          cropInfo: this.afterCropInfo
        },
        before: {
          uuid: this.beforeTempId,
          year: this.beforeYear,
          cropInfo: this.beforeCropInfo
        },
      };

      const submitButton = $('#next');
      submitButton.button('loading');

      const cropResponse = await this.apiHttp.post('/api/v1.0/entries/images/crop', body);
      submitButton.button('reset');

      this.$emit('done', cropResponse.data)
    },
    cancel() {
      this.$emit('cancel')
    }
  }
})
