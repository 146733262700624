

import SharePlaceImageCropper from '@/components/share/SharePlaceImageCropper.vue';
import { defineComponent, ref } from 'vue';
//@ts-ignore
import { SmartTagz } from 'smart-tagz';
import { Quill, QuillEditor } from '@vueup/vue-quill';
import { Location } from '@aws-sdk/client-location';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import CropPreviews from '@/components/common/CropPreviews.vue';
import MapSearch from '@/components/common/MapSearch.vue';
import { Option, ProcessingResult } from '@/utils/types';

const OK_STATUS = 'ok';
const ERROR_STATUS = 'error';
const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!

const defaultTags = ['reddit']
let quill: Option<Quill> = undefined

export default defineComponent({
  name: 'RedditImageCrawlView',
  components: { CropPreviews, SharePlaceImageCropper, SmartTagz, QuillEditor, MapSearch },
  data() {
    return {
      locationClient: undefined as Option<Location>,
      hideButtons: {},
      redditPosts: [] as any[],
      redditScoreThreshold: 2000,
      defaultTags: defaultTags,
      tags: defaultTags,
      input: '',
      redditPostId: null as null | string,
      redditImageData: null as null | any,
      index: 0,
      poiData: {
        title: '',
        description: '',
        tags: defaultTags,
        location: undefined as Option<any>
      },
      entryData: {
        beforeYear: null as null | Number,
        afterYear: null as null | Number,
        beforeCropInfo: null,
        afterCropInfo: null
      },
      progress: {
        crop: undefined as ProcessingResult,
        poiCreate: undefined as ProcessingResult,
        share: undefined as ProcessingResult
      },
      newMarker: undefined
    }
  },
  async mounted() {
    this.locationClient = new Location({
      region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentity({
          region,
        }),
        identityPoolId: identityPoolId
      })
    });

    this.redditPosts = (await this.apiMetaHttp.get('/admin/reddit', {
      params: {
        scorethreshold: this.redditScoreThreshold
      }
    })).data
    this.redditPosts.sort((p1, p2) => p2.score - p1.score)
  },

  methods: {
    async getRedditPosts() {
      this.redditPosts = (await this.apiMetaHttp.get('/admin/reddit', {
        params: {
          scorethreshold: this.redditScoreThreshold
        }
      })).data
      this.redditPosts.sort((p1, p2) => p2.score - p1.score)
    },
    hideRedditPost(postId) {
      const idx = this.redditPosts.findIndex((post) => post.id === postId)
      this.redditPosts.splice(idx, 1)
    },
    cancel() {
      this.redditPostId = null
      this.redditImageData = null
      this.input = ''
      this.poiData = {
        title: '',
        description: '',
        tags: defaultTags,
        location: undefined
      }
      this.entryData = {
        beforeYear: null,
        afterYear: null,
        beforeCropInfo: null,
        afterCropInfo: null
      }
      this.progress = {
        crop: undefined,
        poiCreate: undefined,
        share: undefined
      }
      this.newMarker = undefined
    },
    cancelAndIgnore() {
      this.ignoreRedditPost(this.redditPostId)
      this.hideRedditPost(this.redditPostId)
      this.cancel()
    },
    getDescriptionForQuill() {
      ref(this.poiData.description)
    },
    async submit() {
      const poiJson = {
        title: this.poiData.title,
        descriptionRich: this.poiData.description,
        position: {
          lat: this.poiData.location!.lat,
          lng: this.poiData.location!.lng
        },
        tags: this.poiData.tags
      };

      const entryJson = {
        after: {
          uuid: this.redditImageData.afterId,
          year: this.entryData.afterYear,
          cropInfo: this.entryData.afterCropInfo
        },
        before: {
          uuid: this.redditImageData.beforeId,
          year: this.entryData.beforeYear,
          cropInfo: this.entryData.beforeCropInfo
        },
      };

      const submitButton = $('#finish-poi');
      submitButton.button('loading');
      const cropResponse = await this.apiHttp.post('/api/v1.0/entries/images/crop', entryJson);
      this.progress.crop = cropResponse.status >= 200 && cropResponse.status < 300 ? OK_STATUS : ERROR_STATUS

      const poiResponse = await this.apiHttp.post('/api/v1.0/pois', poiJson)
      this.progress.poiCreate = poiResponse.status >= 200 && poiResponse.status < 300 ? OK_STATUS : ERROR_STATUS

      const linkResponse = await this.apiHttp.post(`/api/v1.0/entries/${cropResponse.data.entryId}/pois`, { poiId: poiResponse.data.id })
      this.progress.share = linkResponse.status >= 200 && linkResponse.status < 300 ? OK_STATUS : ERROR_STATUS
      submitButton.button('reset');

      const redditResponse = await this.apiMetaHttp.put(`/admin/reddit/${this.redditPostId}/${poiResponse.data.id}`)
      console.log(redditResponse)

      const redditLinkResponse = await this.apiMetaHttp.put(`/admin/reddit/${this.redditPostId}`)
      console.log(redditLinkResponse)

      this.hideRedditPost(this.redditPostId)

      setTimeout(() => {
        this.cancel()
      }, 1000)
    },
    parseHTML(input) {
      const doc = new DOMParser().parseFromString(input, 'text/html');
      return doc.documentElement.textContent;
    },
    isValidPostIdOrUrl() {
      const urlRegex = /^https:\/\/www\.reddit\.com\/r\/([a-zA-Z]*)\/comments\/([a-z0-9]{6})/i
      const idRegex = /^[a-z0-9]{6}$/i

      return this.redditPostId?.match(idRegex) != null || this.redditPostId?.match(urlRegex) != null;
    },
    descriptionChanged() {
      console.log('desc changed')
      this.poiData.description = quill.root.innerHTML
    },
    tagChanged(tags) {
      this.poiData.tags = tags
    },
    quillReady(q) {
      quill = q
    },
    primaryCropperChanged(event) {
      this.$refs.secondarycropper.setAspectRatio(event.width / event.height)
    },
    inputChanged() {
      const urlRegex = /^https:\/\/www\.reddit\.com\/r\/([a-zA-Z]*)\/comments\/([a-z0-9]{6})/i
      const idRegex = /^([a-z0-9]{6})$/i
      console.log(this.input)

      if (this.input.match(urlRegex) != null) {
        console.log('url')
        this.redditPostId = urlRegex.exec(this.input)![2]
        return;
      }

      if (this.input.match(idRegex) != null) {
        console.log('id')
        this.redditPostId = idRegex.exec(this.input)![1]
        return;
      }

      this.redditPostId = null
      console.log(this.redditPostId)
    },
    makeDescriptionHtml(postData, title) {
      return `<p>${title}</p><p><br></p><p>Uploaded to <a href="https://redd.it/${postData.postId}" rel="noopener noreferrer" target="_blank">reddit</a> by user <a href="https://www.reddit.com/user/${postData.postAuthor}" rel="noopener noreferrer" target="_blank">${postData.postAuthor}</a></p>`
    },
    stringIndicatesTodayDate(title) {
      const indicators = ['today', 'present', 'now']
      for (const ind of indicators) {
        if (title.toLowerCase().indexOf(ind) > -1) {
          return true
        }
      }

      return false
    },
    async ignoreRedditPost(redditPostId) {
      const response = await this.apiMetaHttp.delete(`/admin/reddit/${redditPostId}`)
      console.log(response)
      this.hideRedditPost(redditPostId)
    },
    async processRedditPost(redditPostId, formInput = false) {
      this.redditPostId = redditPostId;
      this.hideButtons[redditPostId] = true
      const submitButton = formInput ? $('#get-reddit-images') : $(`#submit_${redditPostId}`);
      submitButton.button('loading');
      const resp = await this.apiHttp.get(`/api/v1.0/admin/reddit/${redditPostId}`)
      submitButton.button('reset');
      this.hideButtons[redditPostId] = false
      this.redditImageData = resp.data
      console.log(resp.data)

      this.poiData.title = this.parseHTML(this.redditImageData.title) ?? ''
      this.poiData.tags = this.poiData.tags.concat(this.redditImageData.tags)
      this.poiData.location = this.redditImageData.location;
      this.poiData.description = this.makeDescriptionHtml(this.redditImageData, this.poiData.title)

      this.entryData.beforeYear = this.redditImageData.beforeYear
      if (this.redditImageData.afterYear === undefined && this.stringIndicatesTodayDate(this.poiData.title)) {
        this.entryData.afterYear = new Date().getFullYear()
      } else {
        this.entryData.afterYear = this.redditImageData.afterYear
      }
    },
  }
})
