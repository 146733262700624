
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminViewUsers',
  data() {
    return {
      users: []
    }
  },
  async mounted() {
    const usersResponse = await this.apiHttp.get('/api/v1.0/users');
    this.users = usersResponse.data
  },
  methods: {
    banUser() {
      console.log('ban')
    },
    unbanUser() {
      console.log('unban')
    },
    promoteUserToAdmin() {
      console.log('promote')
    },
  }
})
