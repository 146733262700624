

import SharePlaceImageUploader from '@/components/share/SharePlaceImageUploader.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SharePlaceImageUploadRow',
  components: { SharePlaceImageUploader },
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  emits: ['done', 'cancel'],
  data() {
    return {
      beforeTempId: undefined,
      afterTempId: undefined
    }
  },
  methods: {
    beforeUploadReady(tempId) {
      this.beforeTempId = tempId
    },
    afterUploadReady(tempId) {
      this.afterTempId = tempId
    },
    uploadsReady() {
      return this.beforeTempId !== undefined && this.afterTempId !== undefined
    },
    next() {
      this.$emit('done', this.$data)
    },
    cancel() {
      this.$emit('cancel')
    },
  }
})
