
import AdminViewMap from '@/components/admin/AdminViewMap.vue';
import AdminPageUserManagementView from '@/components/admin/AdminPageUserManagementView.vue';

import NavBar from '@/components/common/NavBar.vue';
import AdminGeneralInformationTab from '@/components/admin/AdminGeneralInformationTab.vue';
import RedditImageCrawlView from '@/components/admin/AdminRedditImageCrawlView.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminPage',
  components: { NavBar, AdminPageUserManagementView, AdminViewMap, AdminGeneralInformationTab, RedditImageCrawlView },
  beforeCreate() {
    if (!localStorage.jwtToken) {
      this.$router.push({ name: 'login' })
    }
  },
})
