
//@ts-ignore
import VueCropper from 'vue-cropperjs'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SharePlaceImageCropper',
  components: { VueCropper },
  props: {
    index: {
      type: Number,
      required: true
    },
    entryUuid: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  emits: ['cropboxchanged', 'primaryCropperChanged'],
  data() {
    return {
      cropBoxData: undefined,
      aspectRatio: 16 / 9
    }
  },
  computed: {
    previewDiv() {
      return `#previews_${this.type}_${this.index}`
    },
    imageSource() {
      return `${this.$store.state.imagesBaseUrl}/tmp/${this.entryUuid}.jpg`
    }
  },
  mounted() {
    //@ts-ignore
    this.cropBoxData = this.$refs.cropper.getCropBoxData()
  },
  methods: {
    setAspectRatio(ratio) {
      const currentCropBox = this.$refs.cropper.getCropBoxData();
      const newCropBox = JSON.parse(JSON.stringify(currentCropBox))
      newCropBox.height = currentCropBox.width / ratio

      // Fix the aspect ratio to the given value
      this.$refs.cropper.setAspectRatio(ratio)
      // Resets the crop box to the last known position
      this.$refs.cropper.setCropBoxData(newCropBox)
    },
    handleCrop() {
      if (this.$refs.cropper === null) {
        return
      }
      //@ts-ignore
      this.cropBoxData = this.$refs.cropper.getCropBoxData()

      const imageWidth = this.$refs.cropper.getImageData().width
      const imageHeight = this.$refs.cropper.getImageData().height
      const cropInfo = {
        left: this.$refs.cropper.getCropBoxData().left / imageWidth,
        right: (this.$refs.cropper.getCropBoxData().left + this.$refs.cropper.getCropBoxData().width) / imageWidth,
        top: this.$refs.cropper.getCropBoxData().top / imageHeight,
        bottom: (this.$refs.cropper.getCropBoxData().top + this.$refs.cropper.getCropBoxData().height) / imageHeight,
      };

      this.$emit('cropboxchanged', cropInfo)
      if (this.role === 'primary') {
        this.$emit('primaryCropperChanged', this.$refs.cropper.getCropBoxData())
      }
    }
  }
})
