import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dcf19ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 1,
  class: "share-dialog-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_SharePlaceRow = _resolveComponent("SharePlaceRow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_SharePlaceRow, { index: 1 })
      ]))
    ])
  ], 64))
}