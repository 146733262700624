
import MainPageNavBarLoginMenu from '@/components/main/MainPageNavBarLoginMenu.vue';
import MainPageNavBarUserMenu from '@/components/main/MainPageNavBarUserMenu.vue';
import { defineComponent } from 'vue';
import { Evidently } from '@aws-sdk/client-evidently';
import { Option } from '@/utils/types';
import { uuidv4 } from '@/utils/misc';

const region = process.env.VUE_APP_AWS_REGION!
const evidentlyProjectName: string = process.env.VUE_APP_EVIDENTLY_PROJECT_NAME!
const runABTests = process.env.VUE_APP_ENV === 'staging';

export default defineComponent({
  name: 'MainPageNavBarNavBar',
  components: { MainPageNavBarUserMenu, MainPageNavBarLoginMenu },
  data() {
    return {
      startTime: undefined as Option<Date>,
      exploreButtonType: runABTests ? '' : 'blue',
      timeToFirstExplore: -1,
      evidentlyClient: undefined as Option<Evidently>,
    }
  },
  async mounted() {
    if (runABTests) {
      const featureName = 'ExploreButtonStyle'

      const configResponse = await this.configApiHttp.get('/web');
      const userCredentials = atob(configResponse.data.evidently);

      const evidentlyClient = new Evidently({
        region,
        credentials: JSON.parse(userCredentials)
      });

      this.evidentlyClient = evidentlyClient;

      const id = new Date().getTime().toString();
      const evaluateFeatureRequest = {
        entityId: id,
        feature: featureName,
        project: evidentlyProjectName,
      };

      const buttonStyleInABTest = await evidentlyClient.evaluateFeature(evaluateFeatureRequest)
      if (buttonStyleInABTest.value?.stringValue !== undefined) {
        this.exploreButtonType = buttonStyleInABTest.value!.stringValue!
        this.startTime = new Date()
      }
    }
  },
  methods: {
    navigateTo(href) {
      window.location.href = href;
    },
    async getRandomPoi() {
      this.$store.commit('selectRandomPoi')

      if (runABTests) {
        await this.getTimeToFirstExplore()
      }
      return
    },
    async getTimeToFirstExplore() {
      if (this.timeToFirstExplore < 0) {
        this.timeToFirstExplore = (new Date().getTime() - this.startTime!.getTime()) * 1.000001;
        const pageLoadTimeData: any = {
          'details': {
            'timeToFirstExplore': this.timeToFirstExplore
          },
          'userDetails':
              {
                'userId': uuidv4(),
                'sessionId': uuidv4()
              }
        };
        const putProjectEventsRequest = {
          project: evidentlyProjectName,
          events: [
            {
              timestamp: new Date(),
              type: 'aws.evidently.custom',
              data: pageLoadTimeData
            },
          ],
        };
        await this.evidentlyClient?.putProjectEvents(putProjectEventsRequest);
      }
    }
  }
})
