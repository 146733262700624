
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'LoginPage',
  methods: {
    makeRedirectUriForProvider(provider) {
      return `${this.$store.state.oauthRedirectUri}/${provider}`
    },
    getLoginUrl(provider) {
      if (provider === 'google') {
        return `https://accounts.google.com/o/oauth2/auth?=&scope=profile+email&client_id=${this.$store.state.googleClientId}&response_type=code&redirect_uri=${this.makeRedirectUriForProvider('google')}&display=popup`
      }

      if (provider === 'facebook') {
        return `https://www.facebook.com/v10.0/dialog/oauth?client_id=${this.$store.state.facebookClientId}&redirect_uri=${this.makeRedirectUriForProvider('facebook')}`
      }
    }
  }
})
