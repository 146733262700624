import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201524a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row share-place-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharePlaceImageUploadRow = _resolveComponent("SharePlaceImageUploadRow")!
  const _component_SharePlaceCropRow = _resolveComponent("SharePlaceCropRow")!
  const _component_SharePlaceMapRow = _resolveComponent("SharePlaceMapRow")!

  return (_ctx.isVisible())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.displayUploadRow)
              ? (_openBlock(), _createBlock(_component_SharePlaceImageUploadRow, {
                  key: 0,
                  index: _ctx.index,
                  onDone: _ctx.uploadComplete,
                  onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                }, null, 8, ["index", "onDone"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.displayCropperRow)
              ? (_openBlock(), _createBlock(_component_SharePlaceCropRow, {
                  key: 0,
                  "before-temp-id": _ctx.uploadTempIds.before,
                  "after-temp-id": _ctx.uploadTempIds.after,
                  index: _ctx.index,
                  onDone: _ctx.cropComplete,
                  onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
                }, null, 8, ["before-temp-id", "after-temp-id", "index", "onDone"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.displayMapRow)
              ? (_openBlock(), _createBlock(_component_SharePlaceMapRow, {
                  key: 0,
                  "entry-id": _ctx.entryId,
                  index: _ctx.index,
                  onDone: _ctx.mapComplete,
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
                }, null, 8, ["entry-id", "index", "onDone"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}