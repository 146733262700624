
import { defineComponent } from 'vue';
import 'leaflet/dist/leaflet.css'
import { Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet';
import PointOfInterestInfo from '@/components/common/PointOfInterestInfo.vue';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import {
  makeDisabledMarker,
  makeRegularMarker,
  makeSelectedDisabledMarker,
  makeSelectedRegularMarker
} from '@/utils/maps';
import { Option, Poi } from '@/utils/types';

const markerIcon = makeRegularMarker()
const markerIconSelected = makeSelectedRegularMarker()
const markerIconDisabled = makeDisabledMarker()
const markerIconDisabledSelected = makeSelectedDisabledMarker()

// https://leaflet-extras.github.io/leaflet-providers/preview/
export default defineComponent({
  name: 'AdminViewMap',
  components: { PointOfInterestInfo, BeforeAfterImagesContainer },
  data() {
    return {
      markerClusterGroup: new MarkerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 40
      }),
      pois: [] as Poi[],
      selectedPoi: undefined as Option<Poi>,
      map: undefined as Option<LeafletMap>,
      deletedPois: {},
      selectedMarker: undefined as Option<Marker>,
      deleteConfirmation: false,
      layerOptions: {
        tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        id: 'mapbox/light-v10'
      }
    }
  },
  async mounted() {
    const poiResponse = await this.apiHttp.get('/api/v1.0/admin/pois');

    if (poiResponse.status === 401) {
      this.$store.commit('logout')
      this.$router.push({ name: 'login' })
    }

    this.pois = poiResponse.data.pois
    const map: LeafletMap = new LeafletMap('mapContainer').setView([48.137154, 11.576124], 5)
    this.map = map;

    const tileLayer = new TileLayer(
        this.layerOptions.tileLayerUrl,
        {
          attribution:
              'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: this.layerOptions.id,
        }
    )

    this.map.addLayer(tileLayer);

    for (const poi of this.pois) {
      const marker: Marker<any> = new Marker([poi.location.lat, poi.location.lng], { icon: poi.status === 'disabled' ? markerIconDisabled : markerIcon })
      marker.on('click', () => {
        this.selectedMarker?.setIcon(this.selectedPoi?.status === 'disabled' ? markerIconDisabled : markerIcon)
        this.selectedPoi = poi
        this.selectedMarker = marker
        this.selectedMarker.setIcon(this.selectedPoi.status === 'disabled' ? markerIconDisabledSelected : markerIconSelected)
        $(window).trigger('resize.twentytwenty');
      })

      this.markerClusterGroup.addLayer(marker);
    }
    //@ts-ignore
    map.addLayer(this.markerClusterGroup);
  },
  methods: {
    async deletePoi() {
      if(this.selectedPoi === undefined) {
        return;
      }
      const poiResponse = await this.apiHttp.delete(`/api/v1.0/pois/${this.selectedPoi.id}`);

      if (poiResponse.status < 300) {
        const index = this.pois.indexOf(this.selectedPoi);
        if (index > -1) {
          this.pois.splice(index, 1);
        }

        //@ts-ignore
        this.map!.removeLayer(this.selectedMarker!)

        this.deletedPois[this.selectedPoi.id] = true
        this.deleteConfirmation = false
      }

      console.log(poiResponse)
    },
    async disablePoi() {
      if (this.selectedPoi === undefined) {
        return;
      }
      const poiResponse = await this.apiHttp.put(`/api/v1.0/admin/pois/${this.selectedPoi?.id}/status`, {
        status: 'disabled'
      });

      if (poiResponse.status < 300) {
        this.selectedPoi.status = 'disabled'
        this.selectedMarker?.setIcon(markerIconDisabledSelected)
      }

      console.log(poiResponse)
    },
    async enablePoi() {
      if (this.selectedPoi === undefined) {
        return;
      }
      const poiResponse = await this.apiHttp.put(`/api/v1.0/admin/pois/${this.selectedPoi.id}/status`, {
        status: 'standard'
      });

      if (poiResponse.status < 300) {
        this.selectedPoi.status = 'standard'
        this.selectedMarker?.setIcon(markerIconSelected)
      }
    }
  },
})
