
import { defineComponent } from 'vue'
import BeforeAfterImage from '@/components/common/BeforeAfterImage.vue';

export default defineComponent({

  name: 'BeforeAfterImagesContainer',
  components: {
    BeforeAfterImage
  },
  props: {
    selectedImageUuid: {
      type: String,
      required: true
    }
  }
})
