
//@ts-ignore
import { SmartTagz } from 'smart-tagz';
import 'smart-tagz/dist/smart-tagz.css';
import { Quill, QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { Rekognition } from '@aws-sdk/client-rekognition';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { defineComponent } from 'vue';
import { Option, ProcessingResult } from '@/utils/types';

import * as buffer from 'buffer';

const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!

const defaultTags: string[] = []
let quill: Option<Quill> = undefined
export default defineComponent({
  name: 'SharePlaceNewPoiDialog',
  components: { SmartTagz, QuillEditor },
  props: {
    entryId: {
      type: String,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
  },
emits: ['cancel'],
  data() {
    return {
      defaultTags: defaultTags as string[],
      title: '',
      apiResponse: undefined as ProcessingResult,
      description: '',
      tags: defaultTags
    }
  },
  async beforeCreate() {
    const rekognitionClient = new Rekognition({
      region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentity({
          region,
        }),
        identityPoolId: identityPoolId
      })
    });

    const imageUrl = `${this.$store.state.imagesBaseUrl}/${this.entryId}/before.jpg`
    const imageAsBuffer = await this.$http.get(imageUrl, { responseType: 'arraybuffer' })

    const labelsResp = await rekognitionClient.detectLabels({
      Image: {
        Bytes: buffer.Buffer.from(imageAsBuffer.data, 'utf-8') /* Strings will be Base-64 encoded on your behalf */,
      },
      MinConfidence: 80
    })

    const labels = labelsResp.Labels!.map(label => label.Name!)

    this.defaultTags = labels
    this.tags = labels
  },
  methods: {
    tagChanged(tags) {
      this.tags = tags
    },
    descriptionChanged() {
      this.description = quill.root.innerHTML
    },
    quillReady(q) {
      quill = q
    },
    async finish() {
      const poiJson = {
        title: this.title,
        descriptionRich: this.description,
        position: {
          lat: this.lat,
          lng: this.lng
        },
        tags: this.tags
      };

      const poiResponse = await this.apiHttp.post('/api/v1.0/pois', poiJson)
      const linkResponse = await this.apiHttp.post(`/api/v1.0/entries/${this.entryId}/pois`, { poiId: poiResponse.data.id })

      if (linkResponse.status === 200 && poiResponse.status === 201) {
        this.apiResponse = 'ok'
      } else {
        this.apiResponse = 'error'
      }
    },
    cancel() {
      this.$emit('cancel')
    }
  }
})
