import { getAmplifyConfig } from '@/config/amplify';
import { EventAttributes } from '@aws-amplify/analytics/src/types/Analytics';
import { Analytics, AWSKinesisProvider } from '@aws-amplify/analytics';
import { Amplify } from '@aws-amplify/core';

Amplify.configure(getAmplifyConfig());
Analytics.addPluggable(new AWSKinesisProvider());

export enum PoiDisplayTrigger {
  Explore = 'Explore',
  Click = 'SelectPoi',
}

export enum UserActivity {
  Login = 'Login',
  Logout = 'Logout'
}

export enum ImageActivity {
  Next = 'NEXT',
  Previous = 'PREV',
}


class ClickStreamHandler {
  static async recordPoiDisplayEvent(poiId: string, trigger: PoiDisplayTrigger) {
    ClickStreamHandler.recordEvent(trigger, { poiId })
  }

  static async recordUserActivity(userId: string, activity: UserActivity, provider) {
    ClickStreamHandler.recordEvent(activity, {
      userId,
      provider
    })
  }

  static async recordImageActivityEvent(poiId: string, entryId: string, activity: ImageActivity) {
    ClickStreamHandler.recordEvent(activity, {
        poiId,
        entryId
      }
    );
  }


  static async recordEvent(name: string, event: EventAttributes) {
    // @ts-ignore
    Analytics.record({
      data: {
        name,
        event
      },
      streamName: process.env.VUE_APP_AWS_KINESIS_STREAM_NAME!
    }, 'AWSKinesis');
  }
}

export {
  ClickStreamHandler
}
