
import 'cropperjs/dist/cropper.css';
import NavBar from '@/components/common/NavBar.vue';
import SharePlaceRow from '@/components/share/SharePlaceRow.vue';
import { defineComponent } from 'vue';
import {decodeJwt} from 'jose'

export default defineComponent({
  name: 'SharePlacePage',
  components: { SharePlaceRow, NavBar },
  async beforeMount() {
    if (localStorage.jwtToken == undefined) {
      this.$router.push({ name: 'login' })
    } else {

      // Not verifying the signature is fine here, as this only leads to a redirect for unauthenticated users. Token will still be verified in the backend in next API interaction
      const tokenExpiration = decodeJwt(localStorage.jwtToken).exp!
      const now = Math.round(Date.now() / 1000)

      if(tokenExpiration < now) {
        this.$router.push({ name: 'login' })
      }
    }
  },
})
