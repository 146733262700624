
import SharePlaceCropRow from '@/components/share/SharePlaceCropRow.vue';
import SharePlaceImageUploadRow from '@/components/share/SharePlaceImageUploadRow.vue';
import SharePlaceMapRow from '@/components/share/SharePlaceMapRow.vue';
import { defineComponent } from 'vue';

/* eslint-disable */
enum Step {
  Upload = 'UPLOAD',
  Crop = 'CROP',
  Map = 'MAP',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}
/* eslint-enable */

export default defineComponent({
  name: 'SharePlaceRow',
  components: { SharePlaceMapRow, SharePlaceImageUploadRow, SharePlaceCropRow },
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      step: Step.Upload,
      uploadTempIds: {
        before: '',
        after: ''
      },
      entryId: ''
    }
  },
  computed: {
    displayCropperRow(): Boolean {
      return (this.step as Step) == Step.Crop
    },
    displayUploadRow(): Boolean {
      return (this.step as Step) == Step.Upload
    },
    displayMapRow(): Boolean {
      return (this.step as Step) == Step.Map
    }
  },
  methods: {
    isVisible(): Boolean {
      const step = this.step as Step;
      return step != Step.Finished && step != Step.Canceled
    },
    uploadComplete(ids): void {
      console.log(`Upload completed with ids ${JSON.stringify(ids)}`)
      this.uploadTempIds.after = ids.afterTempId
      this.uploadTempIds.before = ids.beforeTempId
      this.step = Step.Crop
    },
    cropComplete(cropInfo): void {
      this.entryId = cropInfo.entryId
      this.step = Step.Map
    },
    mapComplete(): void {
      this.step = Step.Finished
    },
    cancel(): void {
      this.step = Step.Canceled
    }
  }
})
