
import { defineComponent } from 'vue';
import MainPageNavBar from './MainPageNavBar.vue';

import 'leaflet/dist/leaflet.css'

import { ClickStreamHandler, PoiDisplayTrigger } from '@/mixins/tracking';
import { LatLng, Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet';
import { Option, Poi } from '@/utils/types';
import {
  getOpenStreetMapAttribution,
  makeIconCreateFunction,
  makeRegularMarker,
  makeSelectedRegularMarker
} from '@/utils/maps';

const markerIcon = makeRegularMarker()

const markerSelectedIcon = makeSelectedRegularMarker()

export default defineComponent({
  name: 'MainPageMapColumn',
  components: {
    MainPageNavBar
  },
  data() {
    return {
      map: undefined as Option<LeafletMap>,
      markers: {} as {[key: string]: Marker},
      layerOptions: {
        tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        id: 'mapbox/light-v10',

      },
      // @ts-ignore
      markerClusterGroup: new MarkerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 40,
        iconCreateFunction: makeIconCreateFunction('marker-cluster')
      })
    }
  },
  watch: {
    '$store.state.selectedPoiIdx': function (newIdx, previousIdx) {
      this.markers[this.$store.state.pois[previousIdx].id].setIcon(markerIcon)
      this.markers[this.$store.state.pois[newIdx].id].setIcon(markerSelectedIcon)

      this.$router.push({ name: 'poi', params: { id: this.$store.state.pois[newIdx].id } })
    },
    '$store.state.pois': function (pois) {
      const entries = pois.reduce((entriesCount, poi) => entriesCount + poi.linkedEntries.length, 0)
      console.log(`${pois.length} Point of Interests with ${entries} Images fetched from API`)
      this.renderMap()
    },
    '$store.state.automaticMapCenter': function () {
      this.map?.setView(new LatLng(
          this.$store.state.automaticMapCenter!.lat,
          this.$store.state.automaticMapCenter!.lng),
          this.map?.getZoom()
      );
    }
  },
  async mounted() {
    this.map = new LeafletMap('map', { zoomControl: false }).setView([48.137154, 11.576124], 6);
    const tileLayer = new TileLayer(
        this.layerOptions.tileLayerUrl,
        {
          attribution: getOpenStreetMapAttribution(),
          maxZoom: 18,
          id: this.layerOptions.id,
        }
    )

    this.map.addLayer(tileLayer);

    navigator.geolocation?.getCurrentPosition(
        (position) => {
          this.map?.setView(new LatLng(position.coords.latitude, position.coords.longitude), 6);
        }
    );

    this.renderMap()
  },
  methods: {
    renderMap() {
      if (this.map != undefined && this.$store.state.pois?.length > 0) {
        for (let poiIdx in this.$store.state.pois) {
          const poi: Poi = this.$store.state.pois[poiIdx]
          const marker = new Marker([poi.location.lat, poi.location.lng], { icon: markerIcon })
          this.markerClusterGroup.addLayer(marker);

          marker.on('click', async () => {
            this.$store.commit('selectPoiByUuid', poi.id)
            await ClickStreamHandler.recordPoiDisplayEvent(poi.id, PoiDisplayTrigger.Click)
          });

          this.markers[poi.id] = marker
        }

        if (this.$route.params.id !== undefined) {
          this.$store.commit('selectPoiByUuid', this.$route.params.id)
          const poi: Option<Poi> = this.$store.state.pois.find((poi) => poi.id === this.$route.params.id)
          if (poi !== undefined) {
            this.map?.setView(
                new LatLng(
                    poi.location.lat,
                    poi.location.lng
                ),
                this.map?.getZoom()
            );
          } else {
            console.log('landing')
            this.$router.push({ name: 'landing' })
          }
        }

        // @ts-ignore
        this.map.addLayer(this.markerClusterGroup);
      }
    }
  }
})
