

import { defineComponent, PropType } from 'vue';
import { Location } from '@aws-sdk/client-location';
import 'leaflet.markercluster'

import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { LatLng, LeafletMouseEvent, Map as LeafletMap, Marker, MarkerClusterGroup, TileLayer } from 'leaflet';
import { Option, Poi, SimpleLatLng } from '@/utils/types';
import { makeGeocodedMarker, makeIconCreateFunction, makeNewPoiMarker, makeRegularMarker } from '@/utils/maps';

const markerIcon = makeRegularMarker()

const geocodedMarkerIcon = makeGeocodedMarker()

const newMarkerIcon = makeNewPoiMarker()

const region = process.env.VUE_APP_AWS_REGION!
const identityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID!
const locationServiceIndexName = process.env.VUE_APP_AWS_LOCATION_SERVICE_INDEX_NAME!

export default defineComponent({
  name: 'MapSearch',
  props: {
    initialGuess: {
      type: Object as PropType<Option<SimpleLatLng>>,
      default: undefined
    }
  },
  emits: ['markerselected', 'locationchanged'],
  data() {
    return {
      newMarker: undefined as Option<Marker<any>>,
      suggestedLocations: [] as any[],
      suggestedLocationIdx: -1,
      suggestedLocationMarkers: new Map(),
      locationClient: undefined as Option<Location>,
      location: undefined,
      geocodedLocations: [],
      suggestedPoisMarkerClustererGroup: new MarkerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 40,
        iconCreateFunction: makeIconCreateFunction('marker-cluster new-pois')
      }),
      existingPoisMarkerClustererGroup: new MarkerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 40,
        iconCreateFunction: makeIconCreateFunction('marker-cluster')
      }),
      map: undefined as Option<LeafletMap>,
      selectedExistingMarker: undefined as Option<Marker<any>>,
      initialGuessMarker: undefined as Option<Marker<any>>,
      selectedPoi: undefined as Option<Poi>,
      poiHoverText: '',
      layerOptions: {
        tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
        accessToken: process.env.VUE_APP_MAPBOX_API_KEY!,
        id: 'mapbox/light-v10',
      }
    }
  },
  computed: {
    showPoiTitleBelowMap() {
      return this.poiHoverText.length > 0
    }
  },
  watch: {
    suggestedLocations: function (newVal) {
      // @ts-ignore
      this.map?.addLayer(this.suggestedPoisMarkerClustererGroup)
      this.suggestedLocationMarkers.clear();
      this.suggestedPoisMarkerClustererGroup.clearLayers()

      // locIdx has type string, see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Statements/for...in
      for (const locIdx in newVal) {
        const suggestLocation = this.suggestedLocations[locIdx]
        const marker = new Marker(new LatLng(suggestLocation.lat, suggestLocation.lng), { icon: geocodedMarkerIcon })

        this.suggestedPoisMarkerClustererGroup.addLayer(marker);
        this.suggestedLocationMarkers.set(locIdx, marker)

        marker.on('click', () => {
          this.suggestedLocationMarkers.forEach((marker) => marker.setIcon(geocodedMarkerIcon))
          this.suggestedLocationIdx = parseInt(locIdx)

          this.selectedPoi = undefined

          if (this.newMarker !== undefined) {
            // @ts-ignore
            this.map?.removeLayer(this.newMarker)
          }

          marker.setIcon(newMarkerIcon);

          this.selectedExistingMarker?.setIcon(markerIcon)
          this.initialGuessMarker?.setIcon(markerIcon)

          this.$emit('locationchanged', suggestLocation)
        })
      }

      // @ts-ignore
      this.map?.addLayer(this.suggestedPoisMarkerClustererGroup)
    }
  },
  async mounted() {
    this.locationClient = new Location({
      region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentity({
          region,
        }),
        identityPoolId: identityPoolId
      })
    });

    this.map = new LeafletMap('map-container', { zoomControl: false }).setView([48.137154, 11.576124], 6);

    this.map.doubleClickZoom.disable();

    const tileLayer = new TileLayer(
        this.layerOptions.tileLayerUrl,
        {
          attribution: '',
          maxZoom: 18,
          id: this.layerOptions.id,
          accessToken: this.layerOptions.accessToken,
        }
    )

    this.map.addLayer(tileLayer);
    // @ts-ignore
    this.map.addLayer(this.existingPoisMarkerClustererGroup)

    if (navigator.geolocation && this.initialGuess === undefined) {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            this.map?.setView(new LatLng(position.coords.latitude, position.coords.longitude), 3);
          }
      );
    }

    if (this.initialGuess !== undefined) {
      const initialGuessGeoLocation = new LatLng(this.initialGuess.lat, this.initialGuess.lng);
      this.initialGuessMarker = new Marker(
          initialGuessGeoLocation,
          { icon: newMarkerIcon }
      )

      // @ts-ignore
      this.map.addLayer(this.initialGuessMarker);
      this.map.setView(initialGuessGeoLocation, 3);

      this.initialGuessMarker.on('click', () => {
        this.suggestedLocationMarkers.forEach((marker) => marker.setIcon(geocodedMarkerIcon))
        this.suggestedLocationIdx = -1

        if (this.newMarker !== undefined) {
          // @ts-ignore
          this.map?.removeLayer(this.newMarker)
        }

        this.selectedExistingMarker?.setIcon(markerIcon)
        this.initialGuessMarker?.setIcon(newMarkerIcon)

        this.$emit('locationchanged', this.initialGuess)
      })
    }

    this.map.on('dblclick', (event: LeafletMouseEvent) => {
      this.selectedPoi = undefined
      this.suggestedLocationIdx = -1;

      this.suggestedLocationMarkers.forEach((marker) => marker.setIcon(geocodedMarkerIcon))

      if (this.newMarker !== undefined) {
        // @ts-ignore
        this.map?.removeLayer(this.newMarker)
      }
      this.newMarker = new Marker(event.latlng, { icon: newMarkerIcon })

      // @ts-ignore
      this.map?.addLayer(this.newMarker);

      this.selectedExistingMarker?.setIcon(markerIcon)
      this.initialGuessMarker?.setIcon(markerIcon)

      this.$emit('locationchanged', event.latlng)
    });
  },
  methods: {
    setPoi(p) {
      this.selectedPoi = p
    },
    async selectRecommendedMarker(location, idx) {
      this.map!.setView(new LatLng(location.lat, location.lng));
      this.suggestedLocationIdx = idx;
      this.suggestedLocationMarkers.forEach((marker) => marker.setIcon(geocodedMarkerIcon))

      // Expects string as key, https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Statements/for...in
      this.suggestedLocationMarkers.get(`${idx}`).setIcon(newMarkerIcon);
      if (this.newMarker !== undefined) {
        // @ts-ignore
        this.map!.removeLayer(this.newMarker)
      }

      this.initialGuessMarker?.setIcon(markerIcon)
      this.$emit('locationchanged', location)
    },
    async geocode() {
      // @ts-ignore
      const geocodeApiResponse = await this.locationClient.searchPlaceIndexForText({
        IndexName: locationServiceIndexName,
        Text: this.location
      })

      // Geocoding sometimes returns results with equal or very close coordinates. This deduplicates the list with a given accuracy of lat lng
      const deduplicationAccuracy = 4
      const deduplicatedResults = [
        ...new Map(geocodeApiResponse.Results!.map(res => [`${res?.Place?.Geometry?.Point![0].toFixed(deduplicationAccuracy)}_${res?.Place?.Geometry?.Point![1].toFixed(deduplicationAccuracy)}`, res])).values()
      ]

      this.suggestedLocations = deduplicatedResults.map(res => ({
        lat: res?.Place?.Geometry?.Point![1],
        lng: res?.Place?.Geometry?.Point![0],
        label: res?.Place?.Label
      }))
    }
  }
})
