import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13f6eae2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "poi-images" }
const _hoisted_2 = {
  id: "sidebar-before-after",
  class: "twentytwenty-container before-after-images-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BeforeAfterImage = _resolveComponent("BeforeAfterImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BeforeAfterImage, {
        "entry-id": _ctx.selectedImageUuid,
        type: "before"
      }, null, 8, ["entry-id"]),
      _createVNode(_component_BeforeAfterImage, {
        "entry-id": _ctx.selectedImageUuid,
        type: "after"
      }, null, 8, ["entry-id"])
    ])
  ]))
}