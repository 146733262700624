
import { defineComponent } from 'vue';
import BeforeAfterImagesContainer from '@/components/common/BeforeAfterImagesContainer.vue';
import SharePlaceNewPoiDialog from '@/components/share/SharePlaceNewPoiDialog.vue';
import MapSearch from '@/components/common/MapSearch.vue';
import { Option, SimpleLatLng } from '@/utils/types';

export default defineComponent({
  name: 'SharePlaceMapRow',
  components: {
    MapSearch,
    SharePlaceNewPoiDialog,
    BeforeAfterImagesContainer
  },
  props: {
    entryId: {
      type: String,
      required: true
    }
  },
emits: ['done', 'cancel'],
  data() {
    return {
      poiType: 'userChoice',
      labels: [],
      createNew: true,
      location: undefined,
      poi: undefined,
      markerPosition: undefined as Option<SimpleLatLng>
    }
  },
  methods: {
    locationChanged(data: SimpleLatLng) {
      console.log(data)
      this.markerPosition = data
    },
    next() {
      this.$emit('done')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
})
