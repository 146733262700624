
import { defineComponent } from 'vue';
import { AwsRum } from 'aws-rum-web';

import MainPageInfoColumn from '@/components/main/MainPageInfoColumn.vue';
import MainPageMapColumn from '@/components/main/MainPageMapColumn.vue';

const useRUM = process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production'

export default defineComponent({
  components: {
    MainPageInfoColumn,
    MainPageMapColumn
  },
  async mounted() {
    if (useRUM) {
      const configResponse = await this.configApiHttp.get('/web');

      try {
        new AwsRum(
            configResponse.data.rum.appId,
            configResponse.data.rum.appVersion,
            configResponse.data.region,
            configResponse.data.rum
        );

      } catch (error) {
        console.log(error)
      }
    }
    const usersResponse = await this.apiHttp.get('/api/v1.0/users/me');

    if (usersResponse.status === 200) {
      this.$store.commit('setJwtToken', localStorage.jwtToken)
      this.$store.commit('setUser', usersResponse.data)
    }

    if (usersResponse.status === 401) {
      console.log('JWT Token rejected with HTTP401')
      this.$store.commit('logout')
    }

    const poisApiResponse = await this.apiHttp('/api/v1.0/pois');
    const pois = poisApiResponse.data.pois;

    this.$store.commit('setPois', pois)
  }
})
