
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OAuthHandler',
  async mounted() {
    const provider = this.$route.params.provider
    const login = await this.apiHttp.get(`/authenticate/${provider}`, {
      params: this.$route.query
    });

    this.$store.commit('setJwtToken', login.data.jwtToken)
    this.$store.commit('setUser', login.data.user)
    this.$router.push({ name: 'landing' });

    console.log('Exchanged Authorization Code for JWT Token')
    console.log(`User ${login.data.user.fullName} logged in via ${provider}`)
  }
})
