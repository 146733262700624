
import {defineComponent} from 'vue';
import { Option } from '@/utils/types';

export default defineComponent({
  name: 'SharePlaceImageUploader',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value: string) => {
        return [
          'before',
          'after'
        ].includes(value)
      }
    }
  },
  emits: ['uploaddone', 'cancel'],
  data() {
    return {
      uuid: (Math.random() * 1000000000000).toFixed(0),
      fileReceived: false,
      tempId: undefined,
      uploadProgress: -1,
      fileInfo: undefined as Option<{
        width?: number,
        height?: number,
        name: string,
        size: number
      }>
    }
  },
  mounted() {
    const uploadInputElement = $(`#upload_input_${this.uuid}`)
    const dropZone = $(`#upload_dropzone_${this.uuid}`)
    const maxWidth = dropZone.width()!

    //@ts-ignore
    uploadInputElement.fileupload({
      url: `${this.$store.state.apiBaseUrl}/api/v1.0/entries/images`,
      headers: { 'Authorization': localStorage.jwtToken },
      dataType: 'json',
      autoUpload: true,
      acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
      maxFileSize: 5000000, // 5 MB
      disableImageResize: /Android(?!.*Chrome)|Opera/.test(window.navigator.userAgent),
      previewMaxWidth: maxWidth,
      previewMaxHeight: maxWidth * 3 / 4,
      imageMaxWidth: 5000,
      imageMaxHeight: 5000,
      progressInterval: 30,
      previewCrop: false,
      previewCanvas: true,
      dropZone: dropZone
    }).on('fileuploaddone', (e, data) => {
      this.tempId = data.result.id
      this.fileInfo!.width = data.result.originalWidth
      this.fileInfo!.height = data.result.originalHeight

      const file = data.files[0];
      $(`#upload_image_preview_${this.uuid}`).html(file.preview);
      this.fileReceived = true

      console.log(`Upload successful, image has tempId=${this.tempId}`)
      this.$emit('uploaddone', this.tempId)
    }).on('fileuploadfail', (e, data) => {
      const statusCode = data.response().jqXHR.status
      console.log(`Upload failed with HTTP${statusCode}`)
    }).on('fileuploadadd', (e, data) => {
      this.fileInfo = {
        name: data.originalFiles[0].name,
        size: data.originalFiles[0].size
      }

      this.uploadProgress = 0
    }).on('fileuploadprogress', (e, data) => {
      this.uploadProgress = data.loaded / data.total * 100
    });
  },
  methods: {
    startUpload() {
      $(`#upload_input_${this.uuid}`).click()
    }
  }
})
