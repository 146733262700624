import axios, { AxiosInstance } from 'axios';

const configApiHttp: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_CONFIG_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: null
});

const apiHttp: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: null
});

const apiMetaHttp: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_META_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: null
});

apiHttp.interceptors.request.use(
  config => {
    const token = localStorage.jwtToken;
    if (token) {
      config.headers!.Authorization = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

apiMetaHttp.interceptors.request.use(
  config => {
    const token = localStorage.jwtToken;
    if (token) {
      config.headers!.Authorization = token;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default {
  data() {
    return {
      apiHttp,
      apiMetaHttp,
      configApiHttp
    }
  },
  methods: {
    async startHeartbeat(intervalSeconds = 60) {
      await apiHttp.get('/api/v1.0/users/me')

      setTimeout(() => {
        this.startHeartbeat(intervalSeconds)
      }, intervalSeconds * 1000);
    },
    apiHttp() {
      return apiHttp
    }
  }
}
