
import VueSlider from 'vue-slider-component'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CropPreviews',
  components: { VueSlider },
  props: {
    index: {
      required: true,
      type: Number
    },
  },
  data() {
    return {
      opacity: 0.5,
    }
  }
})
