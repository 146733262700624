import { createStore } from 'vuex'
import { ClickStreamHandler, ImageActivity, PoiDisplayTrigger, UserActivity } from '@/mixins/tracking';
import { Poi } from '@/utils/types';


export interface StateProps {
  apiBaseUrl: string;
  imagesBaseUrl: string;
  twitterClientId: string;
  googleClientId: string;
  redditPreviewUrl: string;
  selectedPoiIdx: number;
  selectedEntryIdx: number;
  facebookClientId: string;
  jwtToken: string | undefined;
  mainViewBeforeAfterReady: { before: boolean; initialized: boolean; after: boolean };
  user: { [key: string]: any } | undefined;
  pois: Poi[];
  oauthRedirectUri: string;
  automaticMapCenter: {
    lat: number
    lng: number
  } | undefined
}

const state: StateProps = {
  mainViewBeforeAfterReady: {
    before: false,
    after: false,
    initialized: false
  },
  jwtToken: undefined,
  user: undefined,
  selectedPoiIdx: 0,
  selectedEntryIdx: 0,
  pois: [],
  apiBaseUrl: process.env.VUE_APP_API_URL!,
  imagesBaseUrl: process.env.VUE_APP_IMAGES_BASE_URL!,
  oauthRedirectUri: process.env.VUE_APP_OAUTH_REDIRECT_URL!,
  googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID!,
  twitterClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID!,
  facebookClientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID!,
  redditPreviewUrl: process.env.VUE_APP_REDDIT_THUMBNAIL_URL!,
  automaticMapCenter: undefined,
}

const mutations = {
  async nextImage(state, poiIdx) {
    const entriesOfPoi = state.pois[state.selectedPoiIdx].linkedEntries.length;
    state.selectedEntryIdx = (state.selectedEntryIdx + 1) % entriesOfPoi

    const entryId = state.pois[state.selectedPoiIdx].linkedEntries[state.selectedEntryIdx].id
    ClickStreamHandler.recordImageActivityEvent(state.pois[state.selectedPoiIdx].id, entryId, ImageActivity.Next)
  },
  async previousImage(state, poiIdx) {
    const entriesOfPoi = state.pois[state.selectedPoiIdx].linkedEntries.length;
    if (state.selectedEntryIdx === 0) {
      state.selectedEntryIdx = entriesOfPoi - 1
    } else {
      state.selectedEntryIdx--
    }

    const entryId = state.pois[state.selectedPoiIdx].linkedEntries[state.selectedEntryIdx].id
    ClickStreamHandler.recordImageActivityEvent(state.pois[state.selectedPoiIdx].id, entryId, ImageActivity.Previous)
  },
  async selectEntry(state, entryIdx) {
    state.selectedEntryIdx = entryIdx
    //
    // const entryId = state.pois[state.selectedPoiIdx].linkedEntries[state.selectedEntryIdx].id
    // await clickStreamHandler.recordImageActivityEvent(state.pois[state.selectedPoiIdx].id, entryId, ImageActivity.Selected)
  },
  async selectPoi(state, poiIdx) {
    state.selectedEntryIdx = 0
    state.selectedPoiIdx = poiIdx
  },
  async selectPoiByUuid(state, poiUuid) {
    state.selectedEntryIdx = 0

    for (const idx in state.pois) {
      const poi = state.pois[idx]
      if (poi.id === poiUuid) {
        state.selectedPoiIdx = idx
      }
    }
  },
  setPois(state, pois) {
    state.pois = pois
  },
  setMainBeforeAfterReadyStatus(state, payload) {
    state.mainViewBeforeAfterReady[payload.imageType] = payload.isReady
  },
  setMainSliderInitState(state, payload) {
    state.mainViewBeforeAfterReady.initialized = payload
  },
  async selectRandomPoi(state) {
    const poiIdx = Math.floor(Math.random() * state.pois.length)
    mutations.selectPoi(state, poiIdx)

    const poi = state.pois[poiIdx];
    if (poi !== undefined) {
      state.automaticMapCenter = {
        lat: poi.location.lat,
        lng: poi.location.lng,
      }
    }
    ClickStreamHandler.recordPoiDisplayEvent(poi.id, PoiDisplayTrigger.Explore)
  },
  setJwtToken(state, token) {
    state.jwtToken = token
    localStorage.jwtToken = token;
  },
  async setUser(state, user) {
    state.user = user
    localStorage.user = JSON.stringify(user);
    ClickStreamHandler.recordUserActivity(user.id, UserActivity.Login, user.provider)
  },
  async logout(state) {
    if (state.user !== undefined) {
      ClickStreamHandler.recordUserActivity(state.user.id, UserActivity.Logout, state.user.provider)
    }
    state.user = undefined;
    state.jwtToken = undefined;
    localStorage.removeItem('user');
    localStorage.removeItem('jwtToken');
  }
}

export default createStore({
  state,
  mutations
})
