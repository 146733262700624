
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'LoggedIn',
  methods: {
    logout() {
      this.$store.commit('logout');
    },
    navigateTo(href) {
      window.location.href = href;
    }
  }
})
