import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_cropper = _resolveComponent("vue-cropper")!

  return (_openBlock(), _createBlock(_component_vue_cropper, {
    ref: "cropper",
    src: _ctx.imageSource,
    alt: "Source Image",
    "initial-aspect-ratio": _ctx.aspectRatio,
    multiple: true,
    "view-mode": 3,
    moveable: false,
    rotatable: false,
    scalable: false,
    zoomable: false,
    preview: _ctx.previewDiv,
    "check-cross-origin": false,
    onCrop: _ctx.handleCrop
  }, null, 8, ["src", "initial-aspect-ratio", "preview", "onCrop"]))
}