
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  methods: {
    getUser() {
      const userString = localStorage.getItem('user')
      return userString != undefined ? JSON.parse(userString) : undefined
    },
    isAdmin() {
      return this.getUser()?.role === 'admin'
    },
    isLoggedIn() {
      return this.getUser() !== undefined
    },
    logout() {
      this.$store.commit('logout')
      this.$router.push({ name: 'landing' });
    },
    navigateTo(href) {
      window.location.href = href;
    }
  }
})
