

import { defineComponent } from 'vue'
import '../../assets/js/twentytwenty/jquery.twentytwenty.js'
import '../../assets/js/twentytwenty/jquery.event.move.js'

export default defineComponent({
  name: 'BeforeAfterImage',
  props: {
    entryId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,

      validator: (value: string) => {
        return [
          'before',
          'after'
        ].includes(value)
      }
    }
  },
  computed: {
    imageUrl() {
      return `${this.$store.state.imagesBaseUrl}/${this.entryId}/${this.type}.jpg`
    }
  },
  methods: {
    onLoad() {
      this.$store.commit('setMainBeforeAfterReadyStatus', { imageType: this.$props.type, isReady: true })

      const imagesReady = this.$store.state.mainViewBeforeAfterReady.before && this.$store.state.mainViewBeforeAfterReady.after;
      const sliderNotInitialized = !this.$store.state.mainViewBeforeAfterReady.initialized;

      if (imagesReady) {
        if (sliderNotInitialized) {
          this.$store.commit('setMainSliderInitState', true)
          // @ts-ignore
          $('#sidebar-before-after').twentytwenty({
            no_overlay: true
          });
        } else {
          $(window).trigger('resize.twentytwenty');
        }
      }
    },
  }
})
